<template>
  <div
      @click="setDay()"
      class=" text-center my-auto cursor-pointer day-selector"
  >
    <p class="program-calendar-date text-capitalize mb-0" v-html="date[0]"></p>
    <span class="program-calendar-date small text-muted text-capitalize" v-html="date[1]"></span>
    <hr v-show="isSelected" class="selected">
  </div>
</template>

<script>
export default {
  name: "ProgrammingDay",
  props: {
    curdate: {
      required: true,
      type: String
    },
    day: {
      required: true,
      type: String
    },
    date: {
      required:true,
      type: Array
    },
    dateObject:{
      required: true,
      type: undefined
    }
  },
  computed: {
    isSelected(){
      return this.curdate === this.dateObject.format("YYYY-MM-DD")
    }
  },
  methods:{
    setDay: function(){
      this.$emit("setDay",this.dateObject.format("YYYY-MM-DD"))
      // this.$parent.today = this.dateObject.format("YYYY-MM-DD");
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/colors";
hr.selected{
  border-color: $light-blue;
  width: 30px;
  margin: .2em auto;
}
    .programming-calendar{
      .day-selector{
        border-radius: .3em;
        &:hover{
          background-color: $light-blue;
        }
      }

      .program-day{
        font-size: 0.8em;
      }
      .program-calendar-date {
        font-size: 0.6em;
      }
    }
</style>