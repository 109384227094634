<template>
  <transition name="fade"  mode="out-in">
    <div class="col-12">
      <div class="  mb-1" :key="date+'_programs'">
        <Splide :options="{ rewind: false, perPage: ($isMobile() ? 1 : 3), arrows: true, pagination: false,padding: { left: 0, right: 20 }, start:focus}" v-if="programming.length > 0">
          <SplideSlide class="movie-style pb-4 movie-slide-element" v-for="(program,i) in programming"
                       v-bind:key="i+program.start_time">
            <div class="program-detail-header justify-content-center p-2">
              <span class="text-dark text-uppercase time-play">{{program.start_time.toProgrammingDate(locale)}}</span>
            </div>
            
            <div class="program-image" v-bind:style="backgroundGenerator(program.movie)"></div>
            <!-- <img :src="program.movie.horizontal_poster" class="w-100" :alt="program.movie.title"> -->
            <router-link class="a-no-decoration" :to="{name: 'movie', params: {slug: program.movie.slug}}">
              <span class="font-weight-bold pt-3 pb-1 d-block text-white ">{{program.movie.title}}</span>
              <p class="small text-muted" v-html="program.movie.short_description"></p>
            </router-link>
          </SplideSlide>
        </Splide>
      </div>

        <div class="col-12 text-white" v-if="primetime.length > 0 ">
          <h5 class="text-center">{{ $t("Horario Estelar") }}</h5>
          <Splide :key="date+'_prime'" :options="{ rewind: false, perPage: ($isMobile() ? 1 : 3), arrows: $isMobile(), pagination: false, gap: '1em'}" >
            <SplideSlide class="movie-style pb-4" v-for="(program,i) in primetime"
                         v-bind:key="i+program.start_time">
              <div class="primetime">
                <img :src="(program.movie.poster ? program.movie.poster : 'https://via.placeholder.com/210x315/29c1dd/ffffff?text='+program.movie.title)" class="w-100" :alt="program.movie.title">
                <router-link class="a-no-decoration" :to="{name: 'movie', params: {slug: program.movie.slug}}">
                  <span class="font-weight-bold pt-3 pb-1 d-block text-white ">{{program.movie.title}}</span>
                  <span class="small text-muted" v-html="(program.movie.country ? program.movie.country +' | ' : '')"></span>
                  <span class="small text-muted" v-html="( program.movie.genres.length > 0 ? program.movie.genres.map(el => el.name).join(', ')+' | ' : '')"></span><br>
                  <span class="small text-muted" v-html="(program.movie.year ? program.movie.year : '')"></span>
                  <br class="clearfix">
                </router-link>
                <span class=" text-uppercase time-play">{{program.start_time.toProgrammingDate(locale)}}</span>
              </div>

            </SplideSlide>
          </Splide>
        </div>
    </div>
  </transition>

</template>

<script>
import '@splidejs/splide/dist/css/splide.min.css';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import {mapGetters} from "vuex";

export default {
  name: "ProgrammingDetail",
  components: {
    Splide,
    SplideSlide,
  },
  props:{
    focus:{
      required: true,
      type: Number
    },
    programming:{
      required: true,
      type: Array
    },
    date: {
      type: String
    }
  },
  computed:{
    ...mapGetters(["locale"]),
    primetime(){
      return this.programming.filter(p => p.prime_time)
    }
  },
  methods:{
    country: function(country){
      return (country ? ' | '+country.name : '' )
    },
    genre: function(genres){
      return (genres.length > 0 ? genres[0].name+' | ' : '' )
    },
    placeholder: function(url){
      return (url && url !== "" ? url : "/img/placeholder.png")
    },
    backgroundGenerator: function(movie){
      return {
        background: 'url('+(movie.screenshot ?? movie.horizontal_poster)+') no-repeat center center',
        backgroundSize: 'cover'
      }
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/colors";
.time-play{
  font-size: 0.6em;

}
.program-detail-header{
  background: white;
  border-right: 2px solid $light-blue;
}

.program-image{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 0px;
  padding-bottom: 56%;
}
.movie-slide-element{
 max-width:  360px;
}
</style>