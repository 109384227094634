<template>
  <div class="container-fluid  container-xl ">

    <app-section :title="$t('Programación')"></app-section>
<!---->
    <div class="row mx-auto mb-4 no-gutters" id="weekdays">

      <div class="col-12">
          <Splide :options="{
                  rewind: true,
                  paddingRight: '5rem',
                  perPage: ($isMobile() ? 4 : 6),
                  arrows: false,
                  pagination: false
                }">

            <SplideSlide
                v-for="(day,i) in genDays"
                 v-bind:key="i">
              <programming-day
                  :curdate="today"
                  :day="day.name"
                  :date="day.humanDate"
                  :dateObject="day.date"
                  @setDay="setDay"
                   ></programming-day>
            </SplideSlide>
          </Splide>
        <div class="splide__arrows vm-arrows">
          <button @click="prevDays()" class="splide__arrow vm-arrow splide__arrow--prev vm-prev" type="button" aria-controls="splide01-track" aria-label="Previous slide"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40">
            <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
          </svg>
          </button>
          <button @click="nextDays()" class="splide__arrow vm-arrow splide__arrow&#45;&#45;next vm-next" type="button" aria-controls="splide01-track" aria-label="Go to first slide">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40">
              <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
            </svg>
          </button>
        </div>
      </div>

    </div>
    <div class="row ">
      <programming-detail :programming="programming()" :focus="focus" v-bind:key="last_update" :date="today"></programming-detail>
    </div>

  </div>
</template>

<script>
import moment from "moment";
import ProgrammingDay from "@/components/programming/ProgrammingDay";
import ProgrammingDetail from "@/components/programming/ProgrammingDetail";
import AppSection from "@/components/AppSection";

import '@splidejs/splide/dist/css/splide.min.css';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import {mapGetters} from "vuex";

export default {
  name: "Programming",
  // components: {ProgrammingMovie, ProgrammingDetail, ProgrammingDay, ProgrammingSection},
  components: {AppSection,ProgrammingDay,ProgrammingDetail,Splide, SplideSlide},
  computed:{
    ...mapGetters(["locale"]),
    genDays: function(){
      let days = 6;
      let dates = [];
      for (let i = 0; i < days; i++) {
        let d  = moment(this.today).add(i,'days');
        let baseDate = {
          name: d.locale(this.locale).format("dddd"),
          humanDate:d.locale(this.locale).format("dddd [\n] MMMM DD").split('\n'),
          date:d
        };
        dates.push(baseDate)
      }
      return dates;
    },
    currentDayObj: function(){
        let d  = moment(this.today);
        return {
          name: d.locale(this.locale).format("dddd"),
          humanDate:d.locale(this.locale).format("dddd, [\n] MMMM DD"),
          date:d
        };
    }
  },
  data(){
    return {
      limit: 4,
      focus: 0,
      programming_data:{},
      today: "",
      last_update: null
    }
  },
  created(){
    this.setDay(moment().format("YYYY-MM-DD"));
  },
  methods:{
    next: function(){
      this.focus++;
    },
    prev: function(){
      if(this.focus > 0){
        this.focus--;
      }
    },
    prevDays: function(){
      let day = moment(this.today).add(-1,'days');
      this.setDay(day.format("YYYY-MM-DD"));
    },
    nextDays: function(){
      let day = moment(this.today).add(+1,'days');
      this.setDay(day.format("YYYY-MM-DD"));
    },
    setDay: function(date){
      this.today = date;
      this.fetchProgramming(date)
      this.updateFocus();
    },
    updateFocus(){
      if(this.programming_data[this.today] == undefined || this.programming_data[this.today].length == 0){
        return;
      }
      var focus = 0;
      let currendDate = moment().tz('America/New_York');
      if (currendDate.format("Y-MM-DD") === this.today){
        this.programming_data[this.today].forEach((program, index)=>{
          let progDate = moment(program.start_time);
          if (currendDate.isAfter(progDate)){
            focus = index;
          }
        });
      }
      this.focus = focus;
    },
    fetchProgramming: function(date=''){
      if(this.programming_data[date] && this.programming_data[date].length > 0){
        return;
      }
      this.programming_data[date] = [];
      this.$store.dispatch("fetchProgramming",date).then((data)=>{
        this.last_update = date;
        this.programming_data[date] = data;
        this.updateFocus(date);
      })
    },
    programming(){
      return this.programming_data[this.today] || [];
    }

  }
}
</script>

<style lang="scss">
@import "src/assets/styles/colors";
.short-button{
  width: 50px;
}
.vm-arrows{

}
.vm-arrow{
  background: none;
  svg{
    //fill: white;
  }
  &.vm-next{
    right: 0px;
    margin-right: -1em;
  }
}
.vm-prev{
  margin-left: -2em;
}
.vm-next{
  margin-right: -2em;
}

.next-days-hover{
  &:hover{
    background-color: $light-blue;
    cursor: pointer;
    *{
      color: $white !important;
    }
  }
}
</style>